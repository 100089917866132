import classnames from "classnames";
import { format } from "date-fns";
import React, { useContext } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Button, Icon } from "../../../components/Component";
import { CLIENT_PVPLANT_PAGE } from "../../../constants/routes";
import Head from "../../../layout/head/Head";
import EquipmentAndSpareContainer from "../../equipment_spares/containers/EquipmentAndSpareContainer";
import StringListContainer from "../../ticket_management/strings/containers/StringListContainer";
import { PvPlantContext } from "../PvPlantProvider";
import CorrectiveTaskContainer from "../containers/CorrectiveTaskContainer";
import PreventativeTaskContainer from "../containers/PreventativeTaskContainer";
import RemedialTaskContainer from "../containers/RemedialTaskContainer";

const ViewPvPlant = () => {
  //to get current location
  const location = useLocation().search;
  const tab = new URLSearchParams(location).get("tab");

  const history = useHistory();
  const params = useParams();
  const CheckTab = () => {
    switch (tab) {
      case "basic":
        return "1";
      case "corrective":
        return "2";
      case "remedial":
        return "3";
      case "preventative":
        return "4";
      case "string":
        return "5";
      case "equipments":
        return "6";
      case "master":
        return "6";
      case "cctv":
        return "7";
      default:
        return "1";
    }
  };
  const isTabCheck = CheckTab();

  React.useEffect(() => {
    if (tab == "basic") {
      loadPvPlantTask(plantId);
    }
  }, [isTabCheck]);

  const { plantId } = params;

  const pvPlantContext = useContext(PvPlantContext);
  const { currentViewPvPlant, loadPvPlantTask, pvPlantTotalTasks } = pvPlantContext;

  if (!currentViewPvPlant) return <Spinner />;

  const header = (props) => {
    switch (props) {
      case "correctiveTaskTotal":
        return "Corrective";
      case "remedialTaskTotal":
        return "Remedial";
      case "preventativeTaskTotal":
        return "Preventative (Current month)";
      case "stringTotal":
        return "String";
      default:
        return "n/a";
    }
  };
  const switchRoutes = (props) => {
    switch (props) {
      case "correctiveTaskTotal":
        return "corrective";
      case "remedialTaskTotal":
        return "remedial";
      case "preventativeTaskTotal":
        return "preventative";
      case "stringTotal":
        return "string";
      default:
        return "n/a";
    }
  };

  const titleData = (props) => {
    switch (props) {
      case "OPEN":
        return <div style={{ color: "red" }}>New</div>;
      case "IN_PROGRESS":
        return <div style={{ color: "green" }}>In progress</div>;
      case "READY_FOR_APPROVAL":
        return <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>Ready for approval</div>;
      case "COMPLETED":
        return <div style={{ color: "blue" }}>Closed</div>;
      case "VALIDATE":
        return <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>Ready for approval</div>;
      case "CLOSED":
        return <div style={{ color: "blue" }}>Closed</div>;
      default:
        return "n/a";
    }
  };
  const plantTaskDataKeys = Object.entries(pvPlantTotalTasks);
  return (
    <>
      <BlockHeadContent className="mb-1">
        <Head title="Brighter App | Pv-Plant | View" />
        <div className="mt-1" style={{ display: "flex", alignItems: "center" }}>
          <Link to={CLIENT_PVPLANT_PAGE}>
            <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
              <Icon name="arrow-left"></Icon>
              <span>Back</span>
            </Button>
          </Link>
          <div tag="h4" style={{ fontWeight: "bold", fontSize: 20, marginLeft: 5 }}>
            {currentViewPvPlant.name} PV Plant
          </div>
        </div>
      </BlockHeadContent>
      <Block className={"bg-white mt-3"}>
        <Nav tabs className="ml-4" style={{ display: "flex", justifyContent: "space-around" }}>
          <NavItem>
            <NavLink
              id="info-Plants-btn"
              href="#tab"
              className={classnames({ active: isTabCheck === "1" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=basic`);
              }}
            >
              Plant Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="corrective-Plants-btn"
              href="#tab"
              className={classnames({ active: isTabCheck === "2" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=corrective`);
              }}
            >
              Corrective
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="prevevtative-Plants-btn"
              tag="a"
              href="#tab"
              className={classnames({ active: isTabCheck === "3" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=remedial`);
              }}
            >
              Remedial
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="remedial-Plants-btn"
              tag="a"
              href="#tab"
              className={classnames({ active: isTabCheck === "4" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=preventative`);
              }}
            >
              Preventative
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="string-Plants-btn"
              tag="a"
              href="#tab"
              className={classnames({ active: isTabCheck === "5" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=string`);
              }}
            >
              String
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="corrective-Plants-btn"
              tag="a"
              href="#tab"
              className={classnames({ active: isTabCheck === "6" })}
              onClick={(ev) => {
                ev.preventDefault();
                history.push(`${window.location.pathname}?tab=equipments`);
              }}
            >
              Equipment
            </NavLink>
          </NavItem>
          <hr />
        </Nav>
        <TabContent className="mt-2" activeTab={isTabCheck}>
          <TabPane tabId="1">
            <Block className={"bg-white px-4"}>
              <BlockHead size="lg">
                <div className="justify-content-mb-between d-md-flex">
                  <BlockHeadContent>
                    <BlockTitle tag="h3" className={"mt-5"}>
                      {currentViewPvPlant.name}'s Information
                    </BlockTitle>
                  </BlockHeadContent>
                </div>
              </BlockHead>
              <Row>
                {plantTaskDataKeys &&
                  plantTaskDataKeys.map((taskData, index) => {
                    const isStringPage = switchRoutes(taskData[0]) === "string";
                    return (
                      <Col md="6" lg="6" sm="12" className="p-2" m="2" key={`dashboard-${index}`}>
                        <Card className="card-bordered">
                          <CardTitle className="m-3" style={{ fontWeight: "bolder", fontSize: "20px" }}>
                            {header(taskData[0])}
                          </CardTitle>
                          <CardBody>
                            <Row className="match-height form-row justify-content-center">
                              {taskData[1].length &&
                                taskData[1].map((data, index) => {
                                  return (
                                    <Col md="3" lg="3" key={`data-${index}`}>
                                      <Card
                                        id="count-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={(ev) => {
                                          ev.preventDefault();

                                          history.push(
                                            `${window.location.pathname}?tab=${switchRoutes(taskData[0])}&status=${
                                              data.status === "READY_FOR_APPROVAL" && isStringPage
                                                ? "VALIDATE"
                                                : data?.status
                                            }&dashboard=yes`
                                          );
                                        }}
                                      >
                                        {data.status === "OPEN" && (
                                          <CardTitle
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontSize: "40px",
                                              color: "red",
                                            }}
                                          >
                                            {data.count}
                                          </CardTitle>
                                        )}
                                        {(data.status === "COMPLETED" || data.status === "CLOSED") && (
                                          <CardTitle
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontSize: "40px",
                                              color: "blue",
                                            }}
                                          >
                                            {data.count}
                                          </CardTitle>
                                        )}
                                        {(data.status === "READY_FOR_APPROVAL" || data.status === "VALIDATE") && (
                                          <CardTitle
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontSize: "40px",
                                              color: "deeppink",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {data.count}
                                          </CardTitle>
                                        )}
                                        {data.status === "IN_PROGRESS" && (
                                          <CardTitle
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontSize: "40px",
                                              color: "green",
                                            }}
                                          >
                                            {data.count}
                                          </CardTitle>
                                        )}
                                        <CardBody
                                          style={{ display: "flex", justifyContent: "center", fontWeight: "bolder" }}
                                          className="p-0"
                                        >
                                          {titleData(data.status)}
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>

              <Block size="lg" className="mt-2">
                <Card className="card-bordered p-2">
                  <div className="profile-ud-list" style={{ maxWidth: "100%" }}>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Plant Name</span>
                        <span className="profile-ud-value">{currentViewPvPlant.name || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Size</span>
                        <span className="profile-ud-value">{currentViewPvPlant?.size || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Acronym</span>
                        <span className="profile-ud-value">{currentViewPvPlant?.identifier || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Status</span>
                        <span className="profile-ud-value">{currentViewPvPlant?.status || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Plant Manager</span>
                        <span className="profile-ud-value">
                          {currentViewPvPlant.plantManager
                            ? `${currentViewPvPlant.plantManager.firstName} ${currentViewPvPlant.plantManager.lastName}`
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Team Leader</span>
                        <span className="profile-ud-value">
                          {currentViewPvPlant.users
                            ? `${currentViewPvPlant.users.firstName} ${currentViewPvPlant.users.lastName}`
                            : "-"}
                        </span>
                      </div>
                    </div>

                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> Address</span>
                        <span className="profile-ud-value">{currentViewPvPlant?.address || "-"}</span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> OnBoarded At</span>
                        <span className="profile-ud-value">
                          {currentViewPvPlant.onboardedAt
                            ? format(new Date(currentViewPvPlant.onboardedAt || null), "dd/MM/yyyy")
                            : "-"}
                        </span>
                      </div>
                    </div>

                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label"> What3Word Link</span>
                        <span className="profile-ud-value ">
                          {currentViewPvPlant.what3WordLink ? (
                            <NavLink
                              id="web-pvplant"
                              className="p-0"
                              href={currentViewPvPlant.what3WordLink}
                              target={"_blank"}
                            >
                              {currentViewPvPlant?.what3WordLink}
                            </NavLink>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </Block>

              <Block className={"bg-white mt-3"}></Block>
            </Block>
          </TabPane>
          <TabPane tabId="2">
            {tab==="corrective" ? <CorrectiveTaskContainer plantId={currentViewPvPlant.id} />:<></>}
          </TabPane>
          <TabPane tabId="3">
            {tab==="remedial" ? <RemedialTaskContainer plantId={currentViewPvPlant.id} />:<></>}
          </TabPane>
          <TabPane tabId="4">
            {tab ==="preventative" ? <PreventativeTaskContainer plantId={currentViewPvPlant.id} />:<></>}
          </TabPane>
          <TabPane tabId="5">{tab === "string" ? <StringListContainer /> : null}</TabPane>
          <TabPane tabId="6">
            {tab==="equipments"?<EquipmentAndSpareContainer plantId={currentViewPvPlant.id} />:<></>}
          </TabPane>
        </TabContent>
      </Block>
    </>
  );
};

export default ViewPvPlant;
