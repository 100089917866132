import React from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  getAllCorrectivetasks,
  getAllTickets,
  getPvPlantTask,
  getTaskListFilterOptions,
  getAllUsers
} from "./TicketManagementRepository";
import { format } from "date-fns";
import { getTableViewSettings, updateViewSettings } from "../../repositories/ViewRepository";

const initialPaginationState = {
  page: 1,
  size: 50,
};

const initialFilterParams = {
  selectedTaskStatus: [],
  selectedAssignedEngineer: [],
  selectedPlant: [],
  isArchiveSelected: false,
  searchText: "",
  startDate: "",
  resolvedDate: "",
  sortingOrder: "",
  sortingField: "",
  category: "",
  selectedSLA: ""
};

const initialFilterOptions = {
  statuses: [],
};

export const TicketManagementContext = React.createContext({
  getMaintenanceList: async (category) => { },
  getCorrectiveMaintenanceList: async () => { },
  getRemedialMaintenanceList: async () => { },
  maintenanceData: [],
  isFetchingPreventiveData: false,
});

export const TicketManagementProvider = (props) => {
  const [ticketsList, setTicketsList] = React.useState([]);
  const [currentViewTicket, setCurrentViewTicket] = React.useState(null);
  const [tasksColumnSettings, setTasksColumnSettings] = React.useState([]);
  const [dashboardFilterStatus, setDashboardFilterStatus] = useBoolean(false);
  const [userList, setUserList] = React.useState([]);
  const [correctivetasks, setCorrectiveTasks] = React.useState([]);
  const [correctiveList, setCorrectiveList] = React.useState([]);
  const [preventativeList,setPreventativeList]= React.useState([])
  const [remedialList,setRemedialList]= React.useState([])
  const [correctiveColumnSettings, setCorrectiveColumnSettingsList] = React.useState([]);
  const [preventativeColumnSettingsList,setPreventativeColumnSettingsList]= React.useState([])
  const [remedialColumnSettingsList,setRemedialColumnSettingsList]= React.useState([])

  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  const [filtersLoading, flState] = useBoolean(false);

  //Loaders
  const [ticketsLoading, tlState] = useBoolean(false);

  //filter
  const [showFilter, sfState] = useBoolean(false);
  const [isLoadingFilters, ilfState] = useBoolean(false);
  const [usersLoading, ulState] = useBoolean(false);
  const [correctiveLoading, correctiveState] = useBoolean(false);

  const getTicketListFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      selectedTaskStatus,
      isArchiveSelected,
      searchText,
      selectedAssignedEngineer,
      resolvedDate,
      startDate,
      sortingField,
      sortingOrder,
      selectedPlant,
      category,
      selectedSLA
    } = filterParams;
    if (selectedTaskStatus.length) queryParams["taskStatus"] = selectedTaskStatus;
    if (selectedPlant.length) queryParams["taskPlant"] = selectedPlant;
    if (selectedAssignedEngineer.length) queryParams["taskAssignedEngineer"] = selectedAssignedEngineer;
    if (searchText) queryParams["taskText"] = searchText;
    if (isArchiveSelected != null) queryParams["taskArchived"] = isArchiveSelected;
    if (startDate) queryParams["taskStartDate"] = format(new Date(startDate || null), "yyyy-MM-dd");
    if (resolvedDate) queryParams["taskResolvedDate"] = format(new Date(resolvedDate || null), "yyyy-MM-dd");
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (category) queryParams["taskCategory"] = category;
    if (selectedSLA) queryParams["taskSLA"] = selectedSLA;


    return queryParams;
  };

  const getTableSettings = async (entityType) => {
    try {
      const settings = await getTableViewSettings(entityType);
      setTasksColumnSettings(settings)
  
      if(entityType.entityType === "CLIENT_CORRECTIVE_PLANT"){
        setCorrectiveColumnSettingsList(settings)
      }
      if(entityType.entityType === "CLIENT_REMEDIAL_PLANT"){
        setRemedialColumnSettingsList(settings)
      }
      if(entityType.entityType === "CLIENT_PREVENTATIVE_PLANT"){
        setPreventativeColumnSettingsList(settings)
      }
      const constructFilterParams = {
        selectedTaskStatus: settings.filters.taskStatus ?? "",
        isArchiveSelected: settings.filters.taskArchived ?? false,
        sortingOrder: settings.filters.sortingOrder ?? "",
        sortingField: settings.filters.sortingField ?? "",
        selectedPlant: settings.filters.taskPlant ?? "",
        selectedAssignedEngineer: settings.filters.taskAssignedEngineer ?? "",
        startDate: settings.filters.taskStartDate ?? "",
        resolvedDate: settings.filters.taskResolvedDate ?? "",
        selectedSLA: settings.filters.taskSLA ?? "",
        category: settings.filters.taskCategory ?? ""
      };
      handleApplyFilter(constructFilterParams)
      setPagination({
        size: settings.filters.size,
        page: settings.filters.page
      })
      return settings;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  const loadTicketList = async (settings, category, props, dashboard) => {
    tlState.on();
    if (props?.status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [props.status] });
    }
    try {
      const filterParams = getTicketListFilterParams();
      if (category) {
        filterParams.taskCategory = category;
      }
      if (props?.status) {
        filterParams.taskStatus = props.status;
      }
      if (props?.dashboard) {
        filterParams.dashboard = "yes";
      }
      if(props?.plantId){
        filterParams.taskPlant = props.plantId
      }
      const attributes = settings?.fields?.filter((f) => f.isChecked).map((field) => field.field_key);
      const queryParams = Object.assign(filterParams, {
        ...settings.filters,
      });
      const response = await getAllTickets(queryParams, { attributes });
      setTicketsList(response.taskList);
      if(category === "corrective"){
      setCorrectiveList(response.taskList)
      }
      if(category === "remedial"){
        setRemedialList(response.taskList)
        }
        if(category === "preventative"){
          setPreventativeList(response.taskList)
          }
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      tlState.off();
    }
  };

  // VIEW TICKET
  const loadPvPlantTicket = async (taskId) => {
    try {
      const response = await getPvPlantTask(taskId);
      setCurrentViewTicket(response.task);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const loadFilterOptions = async () => {
    try {
      const response = await getTaskListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
    }
  };

  const loadAllUsers = async () => {
    ulState.on();

    try {
      const response = await getAllUsers();
      if(response.length){
        setUserList(response);
      }
     
    } catch (e) {
      // HANDLE ERROR
    } finally {
      ulState.off();
    }
  };

  const loadAllCorrectiveTasks = async (filter) => {
    correctiveState.on();
    try {
      const response = await getAllCorrectivetasks(filter);
      setCorrectiveTasks(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      correctiveState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const checkIsFilterApplied = () => {
    if (
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedAssignedEngineer != 0 ||
      filterParams.selectedPlant != 0 ||
      filterParams.isArchiveSelected ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateTableSettings = async (payload) => {
    try {
      const res = await updateViewSettings(payload);
    } catch (error) {
      throw error;
    }
  };
  const isFilterApplied = checkIsFilterApplied();

  const mContext = {
    isFilterApplied,
    checkIsFilterApplied,
    loadTicketList,
    ticketsLoading,
    pagination,
    ticketsList,
    showFilter,
    sfState,
    handleApplyFilter,
    resetClientListFilter,
    currentViewTicket,
    loadPvPlantTicket,
    filtersLoading,
    filterOptions,
    filterParams,
    dashboardFilterStatus,
    setDashboardFilterStatus,
    getTableSettings,
    isLoadingFilters,
    setTasksColumnSettings,
    tasksColumnSettings,
    tlState,
    loadAllUsers,
    userList,
    usersLoading,
    loadFilterOptions,
    loadAllCorrectiveTasks,
    correctivetasks,
    updateTableSettings,
    correctiveList,
    setCorrectiveColumnSettingsList,
    correctiveColumnSettings,
    preventativeList,remedialList,
    remedialColumnSettingsList,
    setRemedialColumnSettingsList,
    preventativeColumnSettingsList,
    setPreventativeColumnSettingsList,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <TicketManagementContext.Provider value={mContext}>{props.children}</TicketManagementContext.Provider>;
};
export const withTicketManagementProvider = (Container, containerProps) => (props) =>
(
  <TicketManagementProvider>
    <Container {...containerProps} {...props} />
  </TicketManagementProvider>
);
