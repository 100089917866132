import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import TicketListCompact from "../components/TicketList";
import { TicketManagementContext, withTicketManagementProvider } from "../TicketManagementProvider";
import { useLocation, useHistory } from "react-router-dom";

function PreventiveTable() {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");

  const { loadTicketList, ticketsList, ticketsLoading } = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions, dashboardFilterStatus } = useContext(TicketManagementContext);
  const { loadAllUsers, getTableSettings, tlState } = useContext(TicketManagementContext)

  const searchtext = filterParams.searchText;

  const fetchPreventativeTasksData = async () => {
    tlState.on();
   try {
    const settings = await getTableSettings({
      entityType: "CLIENT_PREVENTATIVE_MAINTENANCE",
    });

    if (settings) {
      if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
        const queryParams = new URLSearchParams(location);
        if (queryParams.has("status")) {
          queryParams.delete("status");
          history.replace({
            search: queryParams.toString(),
          });
        }
        await loadTicketList(settings,"preventative",{status});
        
      }
      await loadFilterOptions()
      if (status && !dashboardFilterStatus) {
        loadTicketList(settings,"preventative",{status,dashboard})
      }
    }
   } catch (error) {
    throw error
   } finally {
    tlState.off()
   }
  };

  useEffect(() => {
    fetchPreventativeTasksData()
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    loadAllUsers()
  }, [])

  return (
    <React.Fragment>
      <Head title="Pv-Plant" />
      <Content> <TicketListCompact ticketsList={ticketsList} viewingPage={"preventative"} /></Content>
    </React.Fragment>
  );
}

export default withTicketManagementProvider(PreventiveTable);
