import React from "react";
import { useState } from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  exportClientEquipmentAPI,
  getClientEquipmentAPI,
  getClientEquipmentFilterOptions,
  getClientEquipmentsList,
  getClientStockTransactionAPI,
} from "./EquipmentSparesRepository";
import { getTableViewSettings, updateViewSettings } from "../../repositories/ViewRepository";

export const EquipmentAndSpareContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 50,
};

const initialFilterParams = {
  supplier: [],
  category: [],
  searchText: "",
  plant: [],
  ownership: "",
  isKeyComponent: "",
  status: "",
  sortingOrder: "",
  sortingField: "",
  table: "",
};

export const EquipmentAndSpareProvider = (props) => {
  const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = useState({});
  const [currentClientEquipment, setCurrentClientEquipment] = useState({});
  const [showFilter, sfState] = useBoolean(false);
  const [isExportLoading, iexlState] = useBoolean(false);
  const [isTransactionLoading, itState] = useBoolean(false);
  const [isEquipmentListLoading, ielState] = useBoolean(false);
  const [transactions, setTransactions] = useState();
  const [transactionFilters, setTransactionFilters] = useState({});
  const [transactionPagination, setTransactionPagination] = useState(Object.assign({}, initialPaginationState));
  const [equipmentList, setEquipmentList] = useState([]);
  const [isLoadingFilters, ilfState] = useBoolean(false);
  const [equipmentColumnSettings, setEquipmentColumnSettings] = React.useState([]);

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const getFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      supplier,
      category,
      plant,
      searchText,
      isKeyComponent,
      ownership,
      status,
      sortingOrder,
      sortingField,
      table,
    } = filterParams;
    if (supplier.length) queryParams["supplier"] = supplier;
    if (category.length) queryParams["category"] = category;
    if (plant && plant.length) queryParams["plant"] = plant;
    if (ownership) queryParams["ownership"] = ownership;
    if (searchText) queryParams["searchText"] = searchText;
    if (status) queryParams["status"] = status;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (table) queryParams["table"] = table;
    return { ...queryParams, isKeyComponent };
  };


  const getTableSettings = async (entityType) => {
    try {
      const settings = await getTableViewSettings(entityType);
      setEquipmentColumnSettings(settings)
      const constructFilterParams = {
        searchText: settings.filters?.searchText ?? "",
        category: settings.filters?.category ?? [],
        plant: settings.filters?.plant ?? [],
        ownership: settings.filters?.ownership ?? "",
        supplier: settings.filters?.supplier ?? [],
        isKeyComponent: settings.filters?.isKeyComponent??"",
        sortingOrder: settings.filters?.sortingOrder ?? "",
        sortingField: settings.filters?.sortingField ?? "",
      };
      handleApplyFilter(constructFilterParams)
      setPagination({
        size: settings.filters.size,
        page: settings.filters.page
      })
      return settings;
    } catch (error) {
      throw error;
    }
  };

  const loadClientEquipmentList = async (settings, plantId) => {
    ielState.on();

    try {
      const filterParams = getFilterParams();
      if (plantId) {
        filterParams["plantId"] = plantId;
      }
      const attributes = settings?.fields?.filter((f) => f.isChecked).map((field) => field.field_key);
      const queryParams = Object.assign(filterParams, {
        ...settings.filters,
      });
      const response = await getClientEquipmentsList(queryParams, { attributes });
      setEquipmentList(response.equipmentAndSpareList);
    } catch (e) {
      // CAPTURE EXCEPTION
      console.log({ e });
    } finally {
      ielState.off();
    }
  };

  
  const updateTableSettings = async (payload) => {
    try {
      const res = await updateViewSettings(payload);
    } catch (error) {
      throw error;
    }
  };

  const loadClientEquipmentsFilterOptions = async () => {
    try {
      const response = await getClientEquipmentFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    }
  };

  const loadClientEquipment = async (id) => {
    try {
      const response = await getClientEquipmentAPI(id);
      setCurrentClientEquipment(response.equipment);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const exportClientEquipment = async (equipmentId, params) => {
    try {
      iexlState.on();
      const res = await exportClientEquipmentAPI(equipmentId, params);
      return res;
    } catch (e) {
      console.log("ee", e);
    } finally {
      iexlState.off();
    }
  };

  const loadClientTransaction = async (id) => {
    try {
      itState.on();
      const filterParams = { ...transactionPagination, ...transactionFilters, stockId: id };
      const res = await getClientStockTransactionAPI(filterParams);
      setTransactions(res.stockTransactions);
    } catch (e) {
      console.log("ee", e);
    } finally {
      itState.off();
    }
  };

  const checkIsFilterApplied = () => {
    if (
      filterParams?.category?.length !== 0 ||
      (filterParams?.plant?.length !== 0 && typeof filterParams?.plant === "object")
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();   
  const resetEquipmentListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    filterParams,
    handleApplyFilter,
    filterOptions,
    loadClientEquipmentsFilterOptions,
    pagination,
    loadClientEquipment,
    currentClientEquipment,
    setCurrentClientEquipment,
    showFilter,
    sfState,
    isFilterApplied,
    resetEquipmentListFilter,
    updateTableSettings,
    isExportLoading,
    exportClientEquipment,
    isTransactionLoading,
    loadClientTransaction,
    transactions,
    transactionFilters,
    transactionPagination,
    setTransactionFilters,
    setTransactionPagination,
    equipmentList,
    loadClientEquipmentList,
    isEquipmentListLoading,
    getTableSettings,
    equipmentColumnSettings,
    setEquipmentColumnSettings,
    checkIsFilterApplied,
    ielState,
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
    updateShowSizePage: (size) => setPagination({ ...pagination, size, page: 1 }),
  };
  return <EquipmentAndSpareContext.Provider value={mContext}>{props.children}</EquipmentAndSpareContext.Provider>;
};

export const withEquipmentAndSpareProvider = (Container, containerProps) => (props) =>
  (
    <EquipmentAndSpareProvider>
      <Container {...containerProps} {...props} />
    </EquipmentAndSpareProvider>
  );
